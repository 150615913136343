import { React, Component, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NavbarImport from './components/Navbar';
import Home from './components/Home'
import About from './components/About'
import Portfolio from './components/Portfolio'
import Project from './components/Project'
//import projects from '/db.json'
import useFetch from 'react-fetch-hook';

import {
        Routes,
        Route,
} from "react-router-dom";

const App= () => {
        const {isLoading, data, error} = useFetch(`https://andysheen.github.io/as-website-content/db.json`);
        const projects = data;
        return (
                <>
                 {!isLoading && (
                        <>
                        <NavbarImport />
                        <Routes>
                                <Route  exact path="/" element={<Home />} />
                                <Route  exact path="/about" element={<About />} />
                                <Route  path='/portfolio' element={<Portfolio projects={projects}/>}/>
                                <Route  path="/portfolio/:id" element={<Project projects={projects}/>}/>
                        </Routes>
                        </>
                 )}
                </>
        )
}

export default App;