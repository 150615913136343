import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image } from 'react-bootstrap'



const Portfolio = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
      },[]);

    const projects = props.projects.projects;
    return (
        <>
            <Container fluid className="mt-5" style={{ margin: 0, padding: 0 }}>
                <Row style={{ margin: 0, padding: 0 }}>
                    <Col md="6"  >
                        <h3>Projects</h3>
                    </Col>
                </Row>
                <Row style={{ margin: 0, padding: 0 }}>
                    {projects.map((project, index) => (
                        <Fragment key={index}>
                            <Col md="4" >
                                <Link key={project.id} to={`/portfolio/${project.id}`}>
                                <figure className='figure'>
                                    <Image className='figure-img img-fluid' src={`https://andysheen.github.io/as-website-content/images/${project.img1}`} alt="" fluid />
                                    <figcaption className='figure-caption fs-5'>{project.title}</figcaption>
                                </figure>
                                </Link>
                            </Col>
                        </Fragment>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default Portfolio;
