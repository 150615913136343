import React, { Component, Fragment } from 'react'
import {Container, Row, Col } from "react-bootstrap";


export default class About extends Component {
    render () {
        return (
            <Fragment>
                <Container fluid >
                <Row style={{ margin: 0, padding:0}}>
                <Col md="12">
                    <br/> 
                </Col>
                </Row>
                <Row style={{ margin: 0, padding:10}}>
                <Col md="12">
                    <h2>Hi, I'm Andy. 👋</h2>   
                </Col>
                </Row>
                <Row style={{ margin: 0, padding:10}}>
                <Col fluid  >
                    <h3>I'm a Creative Technologist specialising in developing Electronics, Hardware and Software for Experiential Projects.</h3>   
                </Col>
                </Row>
                <Row style={{ margin: 0, padding:10}}>
                <Col md="12"  >
                    <h3>I also have a background in Sound Engineering and Audio DSP.</h3>   
                </Col>
                </Row>
                <Row style={{ margin: 0, padding:10}}>
                <Col md="12"  >
                    <h3>I'm also Co-Director of &nbsp;
                    <a href="http://v-a-s-t.co">VAST</a></h3>   
                </Col>
                </Row>
                <Row style={{ margin: 0, padding:10}}>
                <Col md="12"  >
                    <h3>Get in touch: <b>andymsheen@gmail.com</b> 📬</h3>   
                </Col>
                </Row>
                </Container>
            </Fragment>
        )
    }
}
