import React, { Component, Fragment } from 'react'

export default class Home extends Component {
    render () {
        return (
            <Fragment>
                <div style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '90vh'
                }} >
                    <h1>Andy Sheen</h1>
                </div>
            </Fragment>
        )
    }
}
