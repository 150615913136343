import React, { Component } from 'react'
import { Navbar, Nav, Container, Col } from 'react-bootstrap';
const NavbarImport = () => {
    return (
        <>

            <Navbar  collapseOnSelect bg="light" variant="light" expand="lg" >
                    <Navbar.Brand style={{marginLeft: "0.6rem"}} href="/">Andy Sheen</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{marginRight:"0.6rem"}}/>
                    <Navbar.Collapse id="navbarScroll" >
                        <Nav className="justify-content-end" style={{marginRight:"0.6rem", width: "100%" }}>
                            <Nav.Link style={{marginLeft: "0.6rem"}} eventKey="1" href="/about">About</Nav.Link>
                            <Nav.Link style={{marginLeft: "0.6rem"}} eventKey="2" href="/portfolio">Portfolio</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default NavbarImport