import React, { Component, Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useParams } from "react-router-dom";

import "./projects.css";

const Project = (props) => {
  const { projects } = props.projects;
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();


  useEffect(() => {
    window.scrollTo(0, 0)
    setIsLoading(false);
    console.log(id);
  }, [id],[]);

  return (
    <>
      {!isLoading && (
        <Container fluid className="mt-5">
          <Row style={{ margin: 0, padding: 0 }}>
            <Col style={{ margin: 0, padding: 0 }} md="6">
              <h1>{projects[id].title}</h1>
            </Col>
          </Row>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col style={{ margin: 0, padding: 0, marginBottom: 6}} md="6" >
              <h4>{projects[id].client}</h4>
            </Col>
          </Row>
          <Row>
            <Col size="12" md="8">
              <Image
                className="figure-img mb-4"
                src={`https://andysheen.github.io/as-website-content/images/${projects[id].img3}`}
                alt=""
                fluid="true"
              />
            </Col>
            <Col md="4" sm="8">
              <Col>{projects[id].outline}</Col>
              <Col>{projects[id].role}</Col>
              <Col className="mt-3 mb-3">{projects[id].impact}</Col>
              <Col className="mt-2 mb-4">
                <a href={projects[id].link} target="_blank">
                  {projects[id].linktag}
                </a>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col size="6" md="4">
              <Image
                className="figure-img img-fluid"
                src={`https://andysheen.github.io/as-website-content/images/${projects[id].img4}`}
                alt=""
                fluid
              />
            </Col>
            <Col size="6" md="4">
              <Image
                className="figure-img img-fluid"
                src={`https://andysheen.github.io/as-website-content/images/${projects[id].img2}`}
                alt=""
                fluid
              />
            </Col>
            <Col md="4" sm="8">
              <h5>Tech Specs</h5>
              <Row>
                <Col>
                  <ul>
                    {Object.keys(projects[id].tech).map((index) => {
                      return (
                        <>
                          <li key={index}>{projects[id].tech[index]}</li>
                        </>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Project;
